import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { theme } from '../styles/theme';

const StyledFooter = styled.footer`
  background: var(--orange-blue-gradient-top-right);
  padding-bottom: 75px;

  a {
    color: white;
    text-decoration: none;
  }

  @media (${theme.bp.mobileL}) {
    padding-bottom: 55px;
  }
`;

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  height: 100px;
  margin: 0 auto;
  ${({ theme }) => theme.mixins.flexBetween};
  padding-top: 20px;

  @media (${theme.bp.desktopS}) {
    padding: 20px 80px 0 80px;
  }

  @media (${theme.bp.tabletL}) {
    padding: 20px 48px 0 48px;
  }

  @media (${theme.bp.mobileL}) {
    height: auto;
    flex-direction: column;
  }
`;

const FooterLeft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: var(--orange);
  }

  div {
    font-size: 18px;
  }

  @media (${theme.bp.mobileL}) {
    width: 100%;

    h1 {
      margin-bottom: 10px;
    }
  }
`;

const FooterRight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    text-transform: uppercase;
    font-size: 12px;
    color: var(--orange);
    letter-spacing: 2.4px;
    font-weight: bold;
  }

  ul {
    display: flex;
    li {
      font-size: 18px;
      &:not(:last-child) {
        margin-right: 44px;
      }
    }
  }

  @media (${theme.bp.tabletL}) {
    ul {
      li {
        &:not(:last-child) {
          margin-right: 22px;
        }
      }
    }
  }

  @media (${theme.bp.mobileL}) {
    width: 100%;
    margin-top: 22px;

    div {
      margin-bottom: 10px;
    }

    ul {
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;

      li {
        width: 50%;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }
`;

const SVGContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
  color: var(--gray-1);
`;

const FooterSVG = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1367.379 157.83'>
    <path
      d='M1367.379 149.864s-257.229 5.915-369.437 3.211-151.717-52.561-238.158-52.561-112.662 58.645-220.139 57.293-208.266-88.443-344.737-87.874S0 143.611 0 143.611V0h1367.379z'
      fill='currentColor'
    />
  </svg>
);

const Footer = () => (
  <StyledFooter>
    <SVGContainer>
      <FooterSVG />
    </SVGContainer>
    <MaxWidthContainer>
      <FooterLeft>
        <h1>
          <Link to='/'>Ankit Tiwari</Link>
        </h1>
        <div>
          Get in touch{' '}
          <a className='hover-animation' href='mailto:ankucodes@gmail.com'>
            ankucodes@gmail.com
          </a>
        </div>
      </FooterLeft>
      <FooterRight>
        <div>Links</div>
        <ul>
          <li>
            <a className='hover-animation' href='https://github.com/anku255' target='__blank' rel='noopener noreferrer'>
              Github
            </a>
          </li>
          <li>
            <a
              className='hover-animation'
              href='https://twitter.com/__anku__'
              target='__blank'
              rel='noopener noreferrer'
            >
              Twitter
            </a>
          </li>
          <li>
            <a className='hover-animation' href='https://codepen.io/anku255' target='__blank' rel='noopener noreferrer'>
              Codepen
            </a>
          </li>
          <li>
            <a
              className='hover-animation'
              href='https://www.linkedin.com/in/anku255/'
              target='__blank'
              rel='noopener noreferrer'
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </FooterRight>
    </MaxWidthContainer>
  </StyledFooter>
);

export default Footer;
