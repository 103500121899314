import mixins from './mixins';

const breakpoints = ['330px', '400px', '480px', '600px', '768px', '900px', '1080px', '1200px', '1400px'];

breakpoints.mobileS = '330px';
breakpoints.mobileM = '400px';
breakpoints.mobileL = '480px';
breakpoints.tabletS = '600px';
breakpoints.tabletL = '768px';
breakpoints.desktopXS = '900px';
breakpoints.desktopS = '1080px';
breakpoints.desktopM = '1200px';
breakpoints.desktopL = '1400px';

export const theme = {
  bp: {
    mobileS: `max-width: 330px`,
    mobileM: `max-width: 400px`,
    mobileL: `max-width: 480px`,
    tabletS: `max-width: 600px`,
    tabletL: `max-width: 768px`,
    desktopXS: `max-width: 900px`,
    desktopS: `max-width: 1080px`,
    desktopM: `max-width: 1200px`,
    desktopL: `max-width: 1400px`,
  },
  colors: {
    orange: '#FE754B',
    yellow: '#fadf54',
    lightblue: '#5D78EB',
    darkblue: '#495FBB',
    'primary-text': '#F3F3F3',
    'gray-1': ' #130737',
    'gray-2': '#E5E5E5',
    'gray-3': '#ECECED',
    'gray-4': '#FFF2FC',
    white: '#ffffff',
    'dark-blue': '#070317',
  },
  breakpoints,
  mixins,
};
