import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
   :root {
    --orange: #FE754B;
    --yellow: #fadf54;
    --lightblue: #5D78EB;
    --darkblue: #495FBB;
    --primary-text: #F3F3F3;
    --gray-1:  #130737;
    --gray-2: #E5E5E5;
    --gray-3: #ECECED;
    --gray-4: #FFF2FC;
    --white: #ffffff;
    --dark-blue: #070317;
    --orange-blue-gradient-left-bottom:  #fe754b linear-gradient(3deg, #130737 0%, #13073729 100%) 0% 0% no-repeat padding-box;
    --orange-blue-gradient-top-right:  #fe754b linear-gradient(180deg, #130737 0%, #13073729 100%) 0% 0% no-repeat padding-box;
   }

   html {
      font-size: 100%;
   }

   body {
      font-family: 'Montserrat', sans-serif;
      font-size: inherit;
      color: var(--primary-text);
      background: var(--gray-1);
      margin: 0;
      line-height: 1.65;
   }

   * {
      box-sizing: border-box;
   }

   a {
      -webkit-tap-highlight-color: transparent;
   }

   ::-moz-selection { background: var(--orange); }
   ::selection { background: var(--orange); }

   h1, h2, h3, h4, h5 {
      margin: 2.75rem 0 1.05em;
      font-weight: bold;
      line-height: 1.15;
   }

   blockquote, ol, p {
    margin-bottom: 1.15rem;
   }

   h1 {
      margin-top: 0;
      font-size: 2.488em;
   }

   h2 {font-size: 2.074em;}

   h3 {font-size: 1.728em;}

   h4 {font-size: 1.44em;}

   h5 {font-size: 1.2em;}


   a.hover-animation {
      position: relative;

     &:before {
         content: "";
         position: absolute;
         width: 0;
         height: 2px;
         bottom: -2px;
         left: 0;
         background-color: var(--white);
         visibility: hidden;
         transition: all 0.2s ease-in-out;
      }

      &:hover:before {
         visibility: visible;
         width: 100%;
      }
   } 
`;
