import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { theme } from '../styles/theme';

const StyledHeader = styled.header`
  height: 70px;
  padding: 0 48px;
  background: var(--orange);
  display: flex;
  justify-content: space-between;

  a {
    color: white;
    text-decoration: none;
  }

  @media (${theme.bp.tabletL}) {
    padding: 0 16px;
  }

  @media (${theme.bp.mobileL}) {
    height: 60px;
  }
`;

const HeaderLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;

    span {
      color: var(--gray-1);
    }
  }
`;

const HeaderRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  div {
    font-size: 18px;

    &:not(:last-child) {
      padding-right: 32px;

      @media (${theme.bp.tabletL}) {
        padding-right: 24px;
      }
    }
  }
`;

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <HeaderLeft>
      <h1>
        <Link to='/'>
          Ankit <span>Tiwari</span>
        </Link>
      </h1>
    </HeaderLeft>
    <HeaderRight>
      <div>
        <Link className='hover-animation' to='/projects'>
          Projects
        </Link>
      </div>
      <div>
        <Link className='hover-animation' to='/blogs/1'>
          Blogs
        </Link>
      </div>
    </HeaderRight>
  </StyledHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
